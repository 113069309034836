<template>
  <div>
    <select-shop id="memberSelectShop" :loadingButton="loadingButton" v-on:getData="getMemberReport"></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <CRow>
            <CCol sm="12" lg="12">
              <div class="row">
                <div class="col-md-10 col-sm-9 col-8">
                  <h2 class="text-dark font-weight-normal" id="memberTitle">
                    {{ $t('textMember') }}
                  </h2>
                  <h5 class="text-dark font-weight-normal" id="memberCount">
                    {{ $t('memberCount') }} ({{ countMember }})
                  </h5>
                </div>

                <div class="col-md-2 col-4 text-right text-success">
                  <router-link id="memberAddLink" style="text-decoration:none;" to="/member/create">
                    <CButton id="memberAddButton" block color="dark" v-if="isEdit">
                      <i class="fi fi-rr-user"></i>
                      {{ $t('addMenber') }}
                    </CButton>
                  </router-link>

                  <CButton id="memberExportButton" class="mt-2" block color="info" v-if="isExport && loadingButton === true"
                    @click="exportMemberReport">
                    <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
                  </CButton>
                  <CButton id="memberExportDisabledButton" class="mt-2" block color="info" v-if="isExport && loadingButton === false" disabled>
                    <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
                  </CButton>
                </div>
              </div>
              <hr />
              <CRow>
                <CCol sm="10">
                  <CInput id="memberSearchInput" :placeholder="$t('searchMember')" v-model="search" @input="getMemberReport()" />
                </CCol>
                <CCol sm="2" v-if="loadingButton === true">
                  <CButton id="memberSearchButton" v-on:click="getMemberReport()" block color="success">
                    {{ $t('search') }}
                  </CButton>
                </CCol>
                <CCol sm="2" v-else-if="loadingButton === false">
                  <CButton id="memberSearchDisabledButton" block color="success" disabled>
                    <CSpinner color="white" size="sm" id="memberSearchSpinner" /> {{ $t('loading') }}
                  </CButton>
                </CCol>
              </CRow>
              <br />
              <div style="overflow-x: auto" id="memberTableContainer">
                <CDataTable :items="items" :fields="fields" :loading="loading" clickableRows
                  @row-clicked="toggleDetails" hover border style="white-space: nowrap;">
                  <template #name="{ item, index }"  >
                    <td class="py-2 text-dark" :id=" `memberTableRow-${index}`">
                      {{ item.name }}
                    </td>
                  </template>
                  <!-- <template #isMember="{ item }">
                    <td class="py-2 text-dark text-center">
                      <img v-if="item.isMember === 'line'" :src="imgSrc" width="20px" />
                      <span v-else> - </span>
                    </td>
                  </template> -->
                  <!-- <template #print="{item}">
                    <td class="text-center">
                      <CButton
                        color="primary"
                        v-on:click="exportMemberPdf(`${item.objectId}`)"
                        variant="outline"
                        square
                        size="sm"
                      >
                        <i class="fa fa-print"></i>
                      </CButton>
                    </td>
                  </template> -->
                  <template #no-items-view>
                    <div class="text-center my-5">
                      <h4 style="color: #ced2d8;">
                        {{ $t('noItem') }}
                      </h4>
                    </div>
                  </template>
                </CDataTable>
              </div>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getMemberReport">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from '@/services/service'
import report from '@/services/report'
import moment from 'moment'
import util from '@/util/util'
import Pagination from '@/containers/Pagination'
import permis from '@/util/permission'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      data: [],
      search: '',
      loadingButton: true,
      loading: false,
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      imgSrc: '../../../img/line-icon.png',
      countMember: 0,
      member: {
        name: '',
        taxID: '',
        address: '',
        postalCode: '',
        phone: '',
        email: '',
        colorCode: '',
        shopObjectId: this.shopObjectId,
      },
      alertTitle: '',
      isSuccess: false,
      isValidate: 0,
      currentAlertCounter: 0,
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users', 'permain']),
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    isPermission() {
      return permis.findPermissionRead('member', this.$route.path)
    },
    isEdit() {
      return permis.findPermissionEdit('member', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('member', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        { key: 'order', label: '#', _classes: 'text-dark font-weight-normal' },
        { key: 'name', label: this.$i18n.t('memberName'), _style: 'width:25%', _classes: 'text-dark font-weight-normal' },
        { key: 'isMember', label: this.$i18n.t('memberSource'), _classes: 'text-dark font-weight-normal' },
        { key: 'dateOfBirth', label: this.$i18n.t('dateOfBirth'), _classes: 'text-dark font-weight-normal' },
        { key: 'tel', label: this.$i18n.t('tel'), _classes: 'text-dark font-weight-normal' },
        { key: 'email', label: this.$i18n.t('email'), _classes: 'text-dark font-weight-normal' },
        { key: 'date', label: this.$i18n.t('dateOfMember'), _classes: 'text-dark font-weight-normal' },
        { key: 'lastPurchase', label: this.$i18n.t('recentVisit'), _classes: 'text-dark font-weight-normal' },
        {
          key: 'netTotal',
          label: this.$i18n.t('paymentTotal'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        { key: 'point', label: this.$i18n.t('balancePoints'), _classes: 'text-right text-dark font-weight-normal' },
        // {
        //   key: 'print',
        //   label: '',
        //   _classes: 'text-center text-dark font-weight-normal',
        //   _style: 'width:5%',
        // },
      ]
    },
    items() {
      const data = this.data || []
      let detail = []
      let createdAt = new Date()
      let lastPurchase = new Date()
      let start = this.meta_data.current_page * 50 - 50

      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        const netTotal = util.convertCurrency(item.netTotal || 0) 
        const point = util.convertNumber(item.point || 0)

        if (data[i].created_at !== undefined) {
          createdAt = moment(data[i].created_at).format('DD/MM/YYYY HH:mm')
        } else {
          createdAt = moment(new Date()).format('DD/MM/YYYY HH:mm')
        }
        let isMember =  ''
        if(item.memberApp === 'silom'){
            isMember = this.$t('store')
        } else if (item.memberApp === 'line'){
           isMember = 'Line'
        } else if (item.memberApp === 'profile') {
          isMember = this.$t('online')
        } else if (item.memberApp === null ){
          isMember = 'Dashboard'
        } else {
          isMember = '-'
        }
        let dateOfBirth = ''
        if (item.dateOfBirth == null ||
          item.dateOfBirth === '') {
          dateOfBirth = '-'
        } else {
          dateOfBirth = moment(item.dateOfBirth).format('DD/MM/YYYY')
        }

        if (item.lastPurchase === undefined) {
          lastPurchase = '-'
        } else {
          lastPurchase = moment(item.lastPurchase).format(
            'DD/MM/YYYY, HH:mm'
          )
        }

        detail.push({
          order: start + i + 1,
          objectId: item.objectId,
          date: createdAt,
          name: (item.name || `${item.firstname} ${item.lastname}`),
          tel: item.phone || '',
          netTotal: netTotal,
          point: point,
          isMember: isMember,
          member: item,
          dateOfBirth: dateOfBirth,
          email: item.email || '-',
          lastPurchase: lastPurchase,
        })
      }
      return detail
    },
  },
  created() {
    this.getMemberReport()
  },
  methods: {
    ...util,
    getMemberReport(page = 1) {
      this.loadingButton = false
      this.loading = true
      const uid = this.uid
      const params = {
        search: this.search,
        page: page,
        limit: 50,
      }
      const headers = { shopObjectId: this.shopObjectId }

      axios({
        url: '/shop/v1.0/getcustomer/' + uid,
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.countMember = res.data.paginate.items
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportMemberReport() {
      this.loadingButton = false
      const uid = this.uid
      const params = {
        search: this.search,
      }
      const headers = { shopObjectId: this.shopObjectId }

      report({
        url: '/shop/v1.0/getcustomer/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'ข้อมูลสมาชิก.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportMemberPdf(memberObjectId) {
      const uid = this.uid
      const params = {
        shopObjectId: this.shopObjectId,
        memberObjectId: memberObjectId,
      }
      const headers = { shopObjectId: this.shopObjectId }

      report({
        method: 'get',
        url: '/print/v1.0/' + uid + '/packagelabel/a4',
        params: params,
        headers: headers,
        responseType: 'arraybuffer',
      })
        .then((response) => {
          let blob = new Blob([response.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob)
          window.open(url)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    toggleDetails(item, index, event) {
      this.$router.push('/member/' + item.objectId + '/getdetail')
    },
    getRowAttributes(item) {
    return {
      id: `memberTableItem${item.objectId}` // กำหนด id โดยใช้ objectId
    };
  }
  },
}
</script>
